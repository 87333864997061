import React from "react";
import { Link } from "gatsby";
import appData from "data/app.json";
import { handleDropdown, handleMobileDropdown } from "common/navbar";

const Navbar = ({ lr, nr, theme }) => {
  return (
    <nav
      ref={nr}
      className={`navbar navbar-expand-lg change ${
        theme === "themeL" ? "light" : ""
      }`}
    >
      <div className="container">
        <Link to="/" className="logo">
          {theme ? (
            theme === "themeL" ? (
              <img ref={lr} src={appData.darkLogo} alt="logo" />
            ) : (
              <img ref={lr} src={appData.lightLogo} alt="logo" />
            )
          ) : (
            <img ref={lr} src={appData.lightLogo} alt="logo" />
          )}
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          onClick={handleMobileDropdown}
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
          <li className="nav-item">
              <Link to={`/`} className="nav-link">
               Home
              </Link>
            </li>
          
            <li className="nav-item dropdown" onClick={handleDropdown}>
              <span
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Works
              </span>
              <div className="dropdown-menu">
                <Link to={`/project-details2/serco`} className="dropdown-item">
                  Serco
                </Link>
                <Link to={`/project-details2/atsap`} className="dropdown-item">
                  ATSAP
                </Link>
                <Link to={`/project-details2/ansp`} className="dropdown-item">
                  ANSP Learning Portal (ALP)
                </Link>
                <Link to={`https://davidromeoleiva1ce5.myportfolio.com`} className="dropdown-item">
                  Photography Portfolio
                </Link>
                <Link to={`/project-details2/itadmin`} className="dropdown-item">
                  IT Administration
                </Link>
                <Link to={`/project-details2/brasil`} className="dropdown-item">
                  Amazonas Documentary
                </Link>
                <Link to={`/project-details2/runway`} className="dropdown-item">
                  Runway Simulator
                </Link>
                <Link to={`/project-details2/studio`} className="dropdown-item">
                  Studio Work
                </Link>
                <Link to={`/project-details2/livestreaming`} className="dropdown-item">
                  Live Streaming
                </Link>
              </div>
            </li>
           
            <li className="nav-item">
              <Link to={`/contact/contact-dark`} className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
