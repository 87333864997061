import React from "react";
import appData from "data/app.json";

const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="item md-mb50">
              <div className="title">
                <h5>Where am I?</h5>
              </div>
              <ul>
                <li>
                  <span className="icon pe-7s-map-marker"></span>
                  <div className="cont">
                    <h6>Location</h6>
                    <p>Washington, DC</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
         
          <div className="col-lg-4">
            <div className="item">
              <div className="logo">
                <img src={appData.lightLogo} alt="" />
                <p> 
                  <a href= "https://www.credly.com/badges/731bffa4-aae6-40e7-8359-ace7dd708f41/public_url">
               <img src= "/img/cts.png" alt="" />
               </a> 
               </p>
              </div>
              <div className="copy-right">
                <p>
                  © 2024 David Leiva
                </p>
              </div>
              <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="731bffa4-aae6-40e7-8359-ace7dd708f41" data-share-badge-host="https://www.credly.com"></div><script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
